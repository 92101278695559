import colors from '../../colors';
import { StartsWithJGameRules } from './rules/StartsWithJGameRules';
import { TriviaGameRules } from './rules/TriviaGameRules';
import { CoselGameRules } from './rules/CoselGameRules';
import { ScribbleGameRules } from './rules/ScribbleGameRules';
import { TTOLGameRules } from './rules/TTOLGameRules';
import { FakerGameRules } from './rules/FakerGameRules';
import { ThisOrThatGameRules } from './rules/ThisOrThatGameRules';
import { CodenamesGameRules } from './rules/CodenamesGameRules';
import { SpeedMeetGameRules } from './rules/SpeedMeetGameRules';
import buttonFactoryImage from '../../../public/games/buttonFactory.png';
import coselImage from '../../../public/games/cosel.png';
import scribbleImage from '../../../public/games/scribble.png';
import speedDatingImage from '../../../public/games/speedDating.png';
import spyImage from '../../../public/games/spy.png';
import thisOrThatImage from '../../../public/games/thisOrThat.png';
import triviaImage from '../../../public/games/trivia.png';
import ttolImage from '../../../public/games/ttol.png';
import startsWithJImage from '../../../public/games/startsWithJ.png';
import codenamesImage from '../../../public/games/codenames.png';
import tabooImage from '../../../public/games/taboo.png';
import teamTriviaGameImage from '../../../public/games/teamTrivia.png';
import drawBattleGameImage from '../../../public/games/drawBattle.png';
import fakeArtistGameImage from '../../../public/games/fakeArtist.png';
import hotSeatGameImage from '../../../public/games/hotseat.png';
import typeRacerGameImage from '../../../public/games/keyboard.png';
import { TabooGameRules } from './rules/TabooGameRules';
import DrawBattleGameRules from './rules/DrawBattleGameRules';
import { TypeRacerRules } from './rules/TypeRacerRules';
var TAGS = {
  POPULAR: {
    title: 'Popular',
    color: colors.primary,
    textColor: colors.black,
    icon: null
  },
  EASY: {
    title: 'Easy',
    color: colors.green,
    icon: null
  },
  ICEBREAKER: {
    title: 'Ice Breaker',
    color: colors.secondary,
    icon: null
  },
  DRAWING: {
    title: 'Drawing',
    color: colors.thirdColor,
    icon: null
  },
  TEAMWORK: {
    title: 'Teamwork',
    color: colors.secondaryLight,
    icon: null
  },
  COMING_SOON: {
    title: 'Coming Soon',
    color: colors.midGrey,
    icon: null
  },
  VIDEO: {
    title: 'Video Call',
    color: colors.darkNavy,
    textColor: colors.white,
    icon: null
  }
};
var TITLE_TAGS = {
  NEW: {
    text: 'New!',
    color: colors.lightGrey,
    img: null
  },
  BETA: {
    text: 'BETA',
    color: colors.lightRed
  }
};
var COMPLEXITIES = {
  EASY: {
    title: 'Easy',
    color: colors.green,
    icon: null
  },
  MEDIUM: {
    title: 'Medium',
    color: colors.primary,
    icon: null
  },
  HARD: {
    title: 'Hard',
    color: colors.red,
    icon: null
  }
};
export default {
  CoselGame: {
    name: 'Cosel',
    shortName: 'cosel',
    description: 'Broken telephone but with pictures. Watch the madness unfold!',
    image: coselImage,
    tags: [TAGS.DRAWING, TAGS.POPULAR],
    complexity: COMPLEXITIES.MEDIUM,
    time: {
      min: 10,
      max: 20,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 12
    },
    rulesComponent: CoselGameRules,
    rules: [{
      text: 'Enter a word or phrase'
    }, {
      text: 'Draw what the previous player wrote'
    }, {
      text: 'Guess what the previous player drew'
    }, {
      text: 'Watch the madness unfold'
    }]
  },
  StartsWithJGame: {
    name: 'Starts With J',
    shortName: 'starts-with-j',
    description: 'Try to answer the prompts that start with a specific letter in a limited amount of time.',
    image: startsWithJImage,
    complexity: COMPLEXITIES.EASY,
    tags: [TAGS.EASY, TAGS.POPULAR],
    time: {
      min: 5,
      max: 10,
      unit: 'm'
    },
    players: {
      min: 2,
      max: 24
    },
    rulesComponent: StartsWithJGameRules,
    rules: [{
      text: 'Each round you will get some prompts'
    }, {
      text: 'Answer as many prompts as you can within the time limit start start with the specified letter'
    }, {
      text: 'After the round is over, vote on whether the other player\'s answers were valid'
    }, {
      text: 'If two players have the same answers, they will not count'
    }]
  },
  ScribbleGame: {
    name: 'Scribble',
    shortName: 'scribble',
    description: 'Try your best to draw a word and have everyone guess it. When someone else is drawing, try to guess what word they are drawing as fast as you can.',
    image: scribbleImage,
    complexity: COMPLEXITIES.EASY,
    tags: [TAGS.EASY, TAGS.DRAWING],
    time: {
      min: 5,
      max: 15,
      unit: 'm'
    },
    players: {
      min: 3,
      max: 100
    },
    rulesComponent: ScribbleGameRules,
    rules: [{
      text: 'When its your turn, choose between three words and try your best to draw it'
    }, {
      text: 'Everyone will try to guess what you are drawing, and will get more points the sooner they guess it'
    }]
  },
  CodenamesGame: {
    name: 'Codenames',
    shortName: 'codenames',
    beta: false,
    titleTag: TITLE_TAGS.NEW,
    description: 'Compete in teams and guess words based on clues from your team\'s cluegiver.',
    image: codenamesImage,
    complexity: COMPLEXITIES.MEDIUM,
    tags: [TAGS.TEAMWORK],
    time: {
      min: 5,
      max: 15,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 10
    },
    rulesComponent: CodenamesGameRules,
    rules: []
  },
  TypeRacerGame: {
    name: 'Type Racer',
    shortName: 'type-racer',
    titleTag: TITLE_TAGS.NEW,
    description: 'Race for who can type the fastest.',
    image: typeRacerGameImage,
    complexity: COMPLEXITIES.EASY,
    tags: [TAGS.EASY],
    time: {
      min: 1,
      max: 5,
      unit: 'm'
    },
    players: {
      min: 2,
      max: 200
    },
    rulesComponent: TypeRacerRules,
    rules: [{}]
  },
  SpyGame: {
    premium: true,
    name: 'Faker',
    shortName: 'faker',
    description: 'Everyone will be prompted with a question. The faker will have to try and blend in.',
    image: spyImage,
    complexity: COMPLEXITIES.HARD,
    tags: [TAGS.ICEBREAKER],
    time: {
      min: 5,
      max: 15,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 10
    },
    rulesComponent: FakerGameRules,
    rules: [{
      text: 'Someone will be randomly assigned the role of the faker'
    }, {
      text: 'Everyone will have to answer the same question, but the faker will not see the question'
    }, {
      text: 'After each question, vote on who you think the faker is'
    }]
  },
  TabooGame: {
    titleTag: TITLE_TAGS.NEW,
    premium: true,
    name: 'Taboo',
    shortName: 'taboo',
    description: 'Try to get your team to guess the word without saying the taboo words.',
    image: tabooImage,
    complexity: COMPLEXITIES.EASY,
    players: {
      min: 4,
      max: 12
    },
    time: {
      min: 5,
      max: 20,
      unit: 'm'
    },
    tags: [TAGS.TEAMWORK],
    rulesComponent: TabooGameRules,
    rules: []
  },
  TTOLGame: {
    name: 'Two Truths, One Lie',
    shortName: 'two-truths-one-lie',
    description: 'Enter two truths and one lie about yourself. Everyone tries to guess your lie.',
    image: ttolImage,
    complexity: COMPLEXITIES.EASY,
    tags: [TAGS.EASY, TAGS.ICEBREAKER],
    time: {
      min: 5,
      max: 10,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 20
    },
    rulesComponent: TTOLGameRules,
    rules: [{
      text: 'Enter two truths and one lie about yourself'
    }, {
      text: "Take turns guessing each other's lie"
    }, {
      text: 'View results and discuss your lie'
    }]
  },
  ThisOrThatGame: {
    premium: true,
    name: 'This or That',
    shortName: 'this-or-that',
    description: 'Given two options, choose one to stay, and the other to vanish from existence...',
    image: thisOrThatImage,
    complexity: COMPLEXITIES.EASY,
    tags: [TAGS.EASY, TAGS.ICEBREAKER],
    time: {
      min: 5,
      max: 10,
      unit: 'm'
    },
    players: {
      min: 2,
      max: 100
    },
    rulesComponent: ThisOrThatGameRules,
    rules: [{
      text: 'Every round, choose between two things'
    }, {
      text: 'The choice that gets the most votes stays, while the other one is removed from existence'
    }, {
      text: 'See which option stays the longest'
    }]
  },
  TriviaGame: {
    premium: true,
    name: 'Trivia',
    shortName: 'trivia',
    description: 'Attempt to answer questions correctly on variety of subjects. The faster you answer, the more points you get!',
    image: triviaImage,
    complexity: COMPLEXITIES.EASY,
    tags: [TAGS.EASY],
    time: {
      min: 5,
      max: 10,
      unit: 'm'
    },
    players: {
      min: 2,
      max: 100
    },
    rulesComponent: TriviaGameRules,
    rules: [{
      text: 'Choose which answer you think is correct'
    }, {
      text: 'Earn points if you get the correct answer and more points the faster you get it'
    }]
  },
  SpeedDatingGame: {
    premium: true,
    name: 'Speed Meet',
    shortName: 'speed-meet',
    beta: true,
    titleTag: TITLE_TAGS.BETA,
    complexity: COMPLEXITIES.EASY,
    description: 'Get a chance to meet people 1 on 1 and respond to a fun prompt.',
    image: speedDatingImage,
    tags: [TAGS.VIDEO, TAGS.ICEBREAKER],
    time: {
      min: 5,
      max: 20,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 100
    },
    rulesComponent: SpeedMeetGameRules,
    rules: [{
      text: 'Pair with each person for a brief time'
    }, {
      text: 'Talk about the prompt, or anything you want!'
    }]
  },
  DrawBattleGame: {
    premium: true,
    beta: true,
    name: 'Draw Battle',
    titleTag: TITLE_TAGS.BETA,
    shortName: 'draw-battle',
    description: 'Like scribble except you are competing against another groups of players.',
    image: drawBattleGameImage,
    complexity: COMPLEXITIES.EASY,
    tags: [TAGS.DRAWING, TAGS.TEAMWORK],
    time: {
      min: 5,
      max: 20,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 200
    },
    rulesComponent: DrawBattleGameRules,
    rules: [{}]
  },
  TeamTriviaGame: {
    comingSoon: true,
    name: 'Team Trivia',
    shortName: 'team-trvia',
    description: 'Work as a team against other teams to answer trivia questions',
    image: teamTriviaGameImage,
    complexity: COMPLEXITIES.EASY,
    tags: [TAGS.COMING_SOON],
    time: {
      min: 5,
      max: 20,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 200
    },
    rules: [{
      text: 'rule 1'
    }]
  },
  FakeArtistGame: {
    comingSoon: true,
    name: 'Fake Artist',
    shortName: 'fake-artist',
    description: 'Players take turns drawing a word stroke by stroke but one player does not know what the word is',
    image: fakeArtistGameImage,
    complexity: COMPLEXITIES.MEDIUM,
    tags: [TAGS.COMING_SOON],
    time: {
      min: 5,
      max: 20,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 200
    },
    rules: [{
      text: 'rule 1'
    }]
  },
  HotSeatGame: {
    comingSoon: true,
    name: 'Hot Seat',
    shortName: 'hot-seat',
    description: 'Players take turns being in the hot seat. Other players can ask them whatever they want',
    image: hotSeatGameImage,
    complexity: COMPLEXITIES.EASY,
    tags: [TAGS.COMING_SOON],
    time: {
      min: 5,
      max: 20,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 200
    },
    rules: [{
      text: 'rule 1'
    }]
  },
  ButtonGame: {
    comingSoon: true,
    name: 'Button Factory',
    shortName: 'button-factory',
    description: 'Work as a team to ensure that every button is pressed when it is supposed to',
    image: buttonFactoryImage,
    complexity: COMPLEXITIES.HARD,
    tags: [TAGS.COMING_SOON],
    time: {
      min: 5,
      max: 20,
      unit: 'm'
    },
    players: {
      min: 4,
      max: 10
    },
    rules: [{
      text: 'rule 1'
    }]
  }
};