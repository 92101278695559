import io from 'socket.io-client';
export var connection = {
  socket: null,
  hash: null
};
function createSocketConnection(hash, navigate, listeners) {
  var socketConnection = io(window.location.origin, {
    query: {
      hash: hash
    }
  });
  connection.socket = socketConnection;
  connection.hash = hash;
  listeners.forEach(function (listener) {
    var name = listener.name,
      cb = listener.cb;
    connection.socket.on(name, cb);
  });
  connection.socket.on('error', function (error) {
    console.error(error);
  });
  return socketConnection;
}
export default function socket(hash, navigate, listeners, forceRefresh, onNewConnection) {
  if (connection.hash !== hash || forceRefresh) {
    if (connection.socket) {
      connection.socket.disconnect();
    }
    onNewConnection();
    return createSocketConnection(hash, navigate, listeners);
  }
  return connection.socket;
}